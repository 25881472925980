import axios from 'axios'
import { Error } from '../../api/types'
import routes from '../../router/routes'
import { deepKeysToSnakeCase, getAccessToken, getAccessTokenForSigning } from '../state/utils'
import { deepKeysToCamelCase } from '../../utilities/helpers'

const HEADERS = {
	AUTHORIZATION: 'Authorization',
	ACCEPT: 'Accept',
	CONTENT_TYPE: 'Content-Type',
}

const MIME_TYPE = {
	JSON: 'application/json',
}

export default () => {
	//  Axios request interceptors
	axios.interceptors.request.use((request) => {
		//  Set the access_token in the request if it is set in local storage
		const isSigningExperience = window.location.pathname === routes.sign.path
		//Prefer to use access token for signing (On signing experience only) because it may be a different user than logged in
		const accessToken = isSigningExperience ? getAccessTokenForSigning() : getAccessToken()

		if (accessToken !== null) {
			request.headers[HEADERS.AUTHORIZATION] = `Bearer ${accessToken}`
		}

		//  If the Accept header isn't set default it to application/json
		if (request.headers[HEADERS.ACCEPT] === undefined) {
			request.headers[HEADERS.ACCEPT] = MIME_TYPE.JSON
		}
		//  If the Content-Type header isn't set default it to application/json
		if (request.headers[HEADERS.CONTENT_TYPE] === undefined) {
			request.headers[HEADERS.CONTENT_TYPE] = MIME_TYPE.JSON
		}

		const data = request.data
		if (data && request.headers[HEADERS.CONTENT_TYPE] === MIME_TYPE.JSON) request.data = deepKeysToSnakeCase(data)

		return request
	})

	// Axios response interceptors
	axios.interceptors.response.use(
		(response) => Promise.resolve(deepKeysToCamelCase(response)),
		(error) => {
			let message: Error = {
				status: 422,
				message: '',
				fields: [],
			}
			if (error.response) {
				const { data } = error.response
				message.status = error.response.status
				message.message = data.message
				message.fields = data.errors !== undefined && data.errors !== null ? data.errors : null
			} else {
				message.status = error.status
				message.message = error.message
				message.fields = error.fields
			}

			return Promise.reject(message)
		}
	)
}
