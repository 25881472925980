// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/fonts/SeansOtherHand.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./assets/fonts/AvenirLTStd-Black.otf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./assets/fonts/AvenirLTStd-Book.otf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./assets/fonts/AvenirLTStd-Medium.otf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./assets/fonts/AvenirLTStd-Roman.otf");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./assets/fonts/sf-ui-display-regular-webfont.ttf");
var ___CSS_LOADER_URL_IMPORT_6___ = require("./assets/fonts/sf-ui-display-regular-webfont.woff");
var ___CSS_LOADER_URL_IMPORT_7___ = require("./assets/fonts/sf-ui-display-regular-webfont.woff2");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
exports.push([module.id, "@font-face {\n\tfont-family: 'SeansOtherHand';\n\tsrc: local('SeansOtherHand'), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype');\n}\n\n:root {\n\tfont-size: calc(16px + (20 - 16) * ((100vw - 320px) / (680 - 320)));\n\tline-height: 24px;\n}\n\n/* html, body{\n    -webkit-overflow-scrolling : touch !important;\n    overflow: auto !important;\n    height: 100% !important;\n} */\n\nbody {\n\tmargin: 0;\n}\n* {\n\tbox-sizing: border-box;\n}\n\n@font-face {\n\tfont-family: 'Avenir Black';\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('opentype');\n}\n@font-face {\n\tfont-family: 'Avenir Book';\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('opentype');\n}\n@font-face {\n\tfont-family: 'Avenir Medium';\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('opentype');\n}\n@font-face {\n\tfont-family: 'Avenir Roman';\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format('opentype');\n}\n@font-face {\n\tfont-family: 'San Francisco Text';\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format('truetype'),\n\t\turl(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format('woff'),\n\t\turl(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format('woff2');\n}\n\n:root {\n\tfont-family: 'Source Sans Pro';\n}\n\n@keyframes slideBelowWindow {\n\t0% {\n\t\ttop: -20vh;\n\t}\n\t100% {\n\t\ttop: 0vh;\n\t}\n}\n@keyframes slideAboveWindow {\n\t0% {\n\t\ttop: 0vh;\n\t}\n\t100% {\n\t\ttop: -20vh;\n\t}\n}\n@keyframes markPulse {\n\t0% {\n\t\topacity: 0;\n\t\ttransform: scale(0, 0);\n\t}\n\t50% {\n\t\topacity: 1;\n\t\ttransform: scale(1, 1);\n\t}\n\t100% {\n\t\ttransform: scale(0, 0);\n\t}\n}\n", ""]);
// Exports
module.exports = exports;
