export interface Route {
	path: string
	name: string
	component: string
}
export interface Routes extends Array<Route> {}

export default {
	sign: {
		path: '/sign',
		name: 'Sign',
		component: 'features/Sign/Sign',
	},
	not_found: {
		path: '*',
		name: 'Page Not Found',
		component: 'features/404/404',
	},
}
