import { isArray, isObject, mapKeys, snakeCase, camelCase, mapValues } from 'lodash'
import CONFIG from '../config'

export const ACCESS_TOKEN = 'accessToken'
export const ACCESS_TOKEN_FOR_SIGNING = 'accessTokenForSigning'

/**
 * Get the access token for the logged in user
 * @returns {string} The access token or null if unauthenticated
 */
export const getAccessToken = () => window.localStorage.getItem(ACCESS_TOKEN)

/**
 * Set the access token for the logged in user
 * @param {string} accessToken The access token
 */
export const setAccessToken = (accessToken) => {
	if (!accessToken) window.localStorage.removeItem(ACCESS_TOKEN)
	else window.localStorage.setItem(ACCESS_TOKEN, accessToken)
}

/**
 * Get the access token for the signing user
 * @returns {string} The access token or null if unauthenticated
 */
export const getAccessTokenForSigning = () => window.localStorage.getItem(ACCESS_TOKEN_FOR_SIGNING)

/**
 * Set the access token for the signing user
 * @param {string} accessToken The access token
 */
export const setAccessTokenForSigning = (accessToken) => {
	if (!accessToken) window.localStorage.removeItem(ACCESS_TOKEN_FOR_SIGNING)
	else window.localStorage.setItem(ACCESS_TOKEN_FOR_SIGNING, accessToken)
}

/**
 * Check to see whether or not the current user is authenticated to sign
 * @returns {boolean} true if authenticated to sign, false otherwise
 */
export const isAuthenticatedForSigning = () => getAccessTokenForSigning() !== null

/**
 * Check to see whether or not the current user is authenticated
 * @returns {boolean} true if authenticated, false otherwise
 */
export const isAuthenticated = () => getAccessToken() !== null

/**
 * Formatter to format one level of an object to snake case
 * @param object The object to covert to snake case
 * @returns {object} witth keys as snake case
 */
export const keysToSnakeCase = (object: any): any => mapKeys(object, (v, k) => snakeCase(k))

/**
 * Formatter to format one level of an object to camel case
 * @param object The object to covert to camel case
 * @returns {object} witth keys as camel case
 */
export const keysToCamelCase = (object: any): any => mapKeys(object, (v, k) => camelCase(k))

/**
 * Helper to set all keys to a specific format
 * @param {object|array} object The object to convert
 * @param {function} fun The function to convert all keys of a object to a specific format
 */
export const deepKeysTo = (object: any, fun: Function | any) => {
	if (isArray(object)) return object.map((v) => deepKeysTo(v, fun))
	const obj = mapValues(object, (v, k) => (isObject(v) ? deepKeysTo(v, fun) : v))
	return fun(obj)
}

/**
 * Recursively set all keys to snake case i.e. exampleKey => example_key
 * Helpful for converting APP/JS keys to API/PHP keys
 * @param {object|array} object The object to convert keys to snake case
 * @returns {object} witth keys as snake case
 */
export const deepKeysToSnakeCase = (object: any): any => deepKeysTo(object, keysToSnakeCase)

/**
 * Recursively set all keys to camel case i.e. example_key => exampleKey
 * Helpful for converting API/PHP keys to APP/JS keys
 * @param {object|array} object The object to convert keys to camel case
 * @returns {object} witth keys as camel case
 */
export const deepKeysToCamelCase = (object: any): any => deepKeysTo(object, keysToCamelCase)

export const url = () => CONFIG.REACT_APP_API_BASE_URL
