import dotenv from 'dotenv'
//The CONFIG class that reads from .env
//Classes are used vs types/interfaces because process.env is not available at compile time and has no types.
//If you need to change a value here, edit the .env instead
const CONFIG = new (class {
	public readonly NODE_ENV = process.env.NODE_ENV ? process.env.NODE_ENV : 'development'
	public readonly PORT = process.env.PORT ? process.env.PORT : 3000
	public readonly REACT_APP_BASE_URL = ''
	public readonly REACT_APP_API_BASE_URL = ''
	public readonly REACT_APP_VERIFY_BASE_URL = ''
	public readonly REACT_APP_SIGNERX_BASE_URL = ''
	public readonly REACT_APP_PROXY_BASE_URL = ''
	public readonly REACT_APP_SIGNERX_URL = ''
	public readonly REACT_APP_PRIVACY_URL = ''
	public readonly REACT_APP_TERMS_URL = ''
	public readonly REACT_APP_TERMS_OF_USE_URL = ''
	public readonly REACT_APP_SIGNER_SUPPORT_URL = ''
	public readonly REACT_APP_HELPSCOUT_BEACON_KEY = ''
	public readonly REACT_APP_ROLLBAR_ACCESS_TOKEN = ''
	public readonly REACT_APP_ENABLE_TIMEOUT = ('false' as 'true') || 'false'
	public readonly REACT_APP_TIMEOUT_MS = ''
	public readonly REACT_APP_ASYM_KEY = ''

	constructor() {
		//Set process.env to have the keys and values you defined in your .env file.
		//Production values are generated on the deployed server (or .ebextensions)
		dotenv.config()

		//Iterate through the .env file and read values into ENV class
		const keys = Object.keys(this)
		const missing: string[] = []
		for (const k of keys) {
			const v = process.env[k] || this[k]
			if (!v) missing.push(k)
			else this[k] = v
		}
		//Return if version is node (server side)
		if (process.versions.node) return
		if (missing.length > 0) {
			throw new Error(`Required environment variables missing: ['${missing.join("','")}']`)
		}
	}
})()

export default CONFIG
