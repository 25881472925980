import React, { lazy } from 'react'
import { Route, Switch } from 'react-router'
import routes, { Routes } from './routes'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router-dom'

const history = createBrowserHistory()

/*
Public route that can be accessed by anyone
 */
const PublicRoute = ({ component, ...rest }) => {
	const Component = lazy(() => import(`../${component}`))
	return <Route {...rest} render={(props) => <Component {...props} />} />
}

//Helper functions to render routes
const renderRoutes = (routes: Routes, ComponentRoute: any) =>
	routes.map((route, index) => route && <ComponentRoute key={index} exact {...route} />)
const renderPublicRoutes = (routes: Routes) => renderRoutes(routes, PublicRoute)

export default () => (
	<Router history={history}>
		<Switch>
			{/* PUBLIC */}
			{renderPublicRoutes([routes.sign])}
			{/* 404 */}
			<PublicRoute path={routes} component={routes.not_found.component} />
		</Switch>
	</Router>
)
