// theme.ts

import { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { theme } from './styleguide'
/*
 :root font is 16px
*/

theme.confettiColors = [
	'#53a9e0',
	'#ff51cf',
	'#53e0c5',
	'#e0c553',
	'#e05380',
	'#95e457',
	'#5580e0',
	'#e09a5b',
	'#9d57e0',
	'#e0556d',
	'#dde659',
	'#53ade0',
	'#6a59e0',
	'#57e05a',
]

theme.actionAreaBar = {
	content: "''",
	position: 'absolute',
	top: 0,
	left: 0,
	right: 0,
	height: '0.3125em',
	backgroundImage: `linear-gradient(
		to left,
		${theme.colors.pictonBlue}, 
		${theme.colors.mediumPurple} 44%, 
		${theme.colors.orchid} 100%)
	`,
}
theme.markPulse = {
	'0%': {
		opacity: 0,
		transform: 'scale(0, 0)',
	},
	'50%': {
		opacity: 1,
		transform: 'scale(1, 1)',
	},
	'100%': {
		transform: 'scale(0, 0)',
	},
}

export default function useTheme() {
	return useContext(ThemeContext)
}

export { theme, useTheme }
