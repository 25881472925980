export const theme = {
	space: [
		'0.5em',
		'1em',
		'1.5em',
		'2em',
		'2.5em',
		'3em',
		'3.5em',
		'4em',
		'4.5em',
		'5em',
		'5.5em',
		'6em',
		'6.5em',
		'7em',
		'7.5em',
		'8em',
	],
	measure: ['8em', '16em', '24em', '32em', '40em', '48em', '56em', '64em'],
	fontSizes: [
		'0.625em', // 10 / 16 = 0.625
		'0.725em', // 11.6 / 16 = 0.725
		'0.8125em', // 13 / 16 = 0.8125
		'0.875em', // 14 / 16 = 0.875
		'0.9375em', // 15 / 16 = 0.9375
		'1em',
		'1.0375em', // 16.6 / 16 = 1.0375
		'1.125em', // 18 / 16 = 1.125
		'1.1875em', //19 / 16 = 1.1875
		'1.1875em', //22 / 16 = 1.375
		'1.48125em', // 23.7 / 16 = 1.48125
		'3em',
		// 17 / 16 =
	],
	lineHeights: [0.7, 1, 1.13, 1.2, 1.3, 2],
	letterSpacings: [0, 0.1, 0.24],
	fonts: {
		SourceSansPro: "'Source Sans Pro'",
		SourceSansProSemi: "'SourceSansPro-SemiBold'",
		SourceSansProBold: "'SourceSansPro-Bold'",
		SourceSansProBlack: "'SourceSansPro-Black'",
		AvenirHeavy: "'Avenir-Heavy'",
		AvenirMedium: "'Avenir-Medium'",
		AvenirHeavyOblique: "'Avenir-HeavyOblique'",
		SanFran: "'San Francisco Text'",
		LaBelleAurore: "'La Belle Aurore', cursive",
	},
	radii: [
		8,
		'1.35em',
		'7vw', // 22.8
	],
	colors: {
		mandy: '#E8456E',
		limedSpruce: '#3B4951',
		aquaHaze: '#f4f8f9',
		shuttleGray: '#535e68',
		boulder: '#797979',
		regentGray: '#7d8fa0',
		white: '#FFFFFF',
		wildWatermelon: '#FF4378',
		orchid: '#C43BE5',
		azureRadiance: '#00A9FF',
		curiousBlue: '#209FD5',
		pictonBlue: '#338CED',
		shakespeare: '#4FB3D9',
		aquamarineBlue: '#76E1E5',
		shamrock: '#50DB90',
		valid: '#00cc6f',
		cornflowerBlue: '#7968FF',
		mediumPurple: '#9557E7',
		athensGray: '#F6F8F9',
		porcelain: '#E5E8EA',
		porcelain2: '#F5F7F8',
		mercury: '#E1E1E1',
		geyser: '#DAE2E7',
		loblolly: '#C5CED6',
		masala: '#4E4E4E',
		oxfordBlue: '#3F4D5C',
		oxfordBlue2: '#2F3D4B',
		mako: '#3E454D',
		athensGray2: '#E5E9EC',

		mineShaft: '#2E2E2E',
		mineShaft2: '#353535',

		baliHai: '#91A9B6',
		matisse: '#166C8C',
		codGray: '#121212',
		dorado: '#535353',
		black: '#000000',
		cadetBlue: '#A8B4BE',
		cadetBlue2: '#A9BCC6',
		hitGray: '#AAB4BD',
	},

	zIndex: {
		dialog: 999,
	},
}
