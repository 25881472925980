import React from 'react'

// import Flexbox from '../Flexbox'
// import LoadingBar from 'react-redux-loading'

import logo from '../../assets/img/signerx-logo.png'

import styled from 'styled-components/macro'

const style = {
	loadingBar: {
		width: '100%',
		height: '100%',
		backgroundColor: '#00a9ff',
	},
}

const Loading = () => {
	return (
		<Overlay>
			<Conatiner>
				<Logo src={logo} alt={'logo'} />
				<LoadingBarConatiner>{/* <LoadingBar style={style.loadingBar} /> */}</LoadingBarConatiner>
			</Conatiner>
		</Overlay>
	)
}
Loading.defaultProps = {
	size: 60,
}

const Overlay = styled.div({
	display: 'flex',
	flexDirection: 'column',
	alignItems: ' center',
	justifyContent: ' center',
	width: '100%',
	height: '100vh',
	overflow: 'hidden',
	position: 'absolute',
	background: '#fff',
	zndex: '9999',
	top: '0',
	left: '0',
	right: '0',
})

const Conatiner = styled.div({
	display: 'flex',
	maxWidth: '480px',
	flexDirection: 'column',
	alignItems: ' center',
})

const LoadingBarConatiner = styled('div')({
	position: 'relative',
	left: '0',
	right: '0',
	width: '50vw',
	height: '1.5vh',
	maxWidth: '330px',
	overflow: 'hidden',
	backgroundColor: '#f6f8f9',
	border: 'solid 0.7px #dae2e7',
	borderRadius: '6.2px',
})

const Logo = styled('img')({
	maxWidth: '170px',
	width: '100%',
	height: 'auto',
	marginBottom: '2.5vh',
})

export default Loading
