import React, { FunctionComponent, Suspense } from 'react'
import initializeAxios from './services/axios/axios'
import router from './router/router'
import { ThemeProvider } from 'styled-components'
import { theme } from './config/theme'
import Loading from './components/Loading/Loading'
import './index.css'

initializeAxios()
const App: FunctionComponent = () => (
	<Suspense fallback={<Loading />}>
		<ThemeProvider theme={theme}>{router()}</ThemeProvider>
	</Suspense>
)

export default App
